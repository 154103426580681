<template>
  <div id="homeContainer">
    <tool-bar-home></tool-bar-home>
    <div class="paddingH3em">
      <v-row align="center">
        <v-col cols="3" align="right">
          <v-avatar>
            <v-img src="@/assets/logoCV.png"></v-img>
          </v-avatar>
        </v-col>
        <v-col cols="9" align="left">
          <h1>{{ $t("title.parte1")}} <span class="highlight">{{ $t("title.parte2")}} </span></h1>
        </v-col>
      </v-row>

      <!-- <v-row align="center" justify="center">
        <v-col align="center">
        <v-avatar>
          <v-img src="@/assets/logoCV.png"></v-img>
        </v-avatar>
        </v-col>
      </v-row> -->

      <v-row align="left">
        <v-col cols="3"></v-col>
        <v-col cols="9" align="left">
          <font size="4">{{ $t("subtitle") }}</font>
        </v-col>
      </v-row>
    </div>
    <div>
      <carousel-3d
        :controls-visible="true"
        :clickable="false"
        :height="465"
        :width="310"
        :perspective="0"
        :space="400"
      >
        <slide v-for="(slide, i) in arrayOfTemplates" :index="i" :key="i">
          <template slot-scope="{ index, isCurrent, leftIndex, rightIndex }">
            <h3>{{ slide.name }}</h3>
            <img
              :data-index="index"
              :class="{
                current: isCurrent,
                onLeft: leftIndex >= 0,
                onRight: rightIndex >= 0,
              }"
              :src="slide.src"
            />
            <div v-if="isCurrent" align="center" id="currentTemplate">
              <v-btn @click="pulsarPlantilla(slide.name)" color="info"
                >{{$t("start")}}</v-btn
              >
            </div>
          </template>
        </slide>
      </carousel-3d>
    </div>
    <footer-home />
  </div>
</template>

<script>
import { Carousel3d, Slide } from "vue-carousel-3d";
import imgDark from "../../public/Templates/TDark.png";
import imgPrimary from "../../public/Templates/TPrimary.png";
import imgElegant from "../../public/Templates/TElegant.png";
import imgSimple from "../../public/Templates/TSimple.png";
import imgModern from "../../public/Templates/TModern.png";
import imgConcept from "../../public/Templates/TConcept.png";
import imgCombined from "../../public/Templates/TCombined.png";
import imgSublime from "../../public/Templates/TSublime.png";
import imgAmbience from "../../public/Templates/TAmbience.png";
import imgCascade from "../../public/Templates/TCascade.png";

import { mapActions } from "vuex";
import ToolBarHome from "../components/ToolBarHome.vue";
import FooterHome from "../components/FooterHome.vue";

export default {
  data: () => ({
    arrayOfTemplates: [
      { name: "Dark", src: imgDark },
      { name: "Simple", src: imgSimple },
      { name: "Modern", src: imgModern },
      { name: "Elegant", src: imgElegant },
      { name: "Sublime", src: imgSublime },
      { name: "Concept", src: imgConcept },
      { name: "Combined", src: imgCombined },
      { name: "Primary", src: imgPrimary },
      { name: "Ambience", src: imgAmbience },
      { name: "Cascade", src: imgCascade },
    ],
    drawer: false,
  }),
  components: {
    Carousel3d,
    Slide,
    ToolBarHome,
    FooterHome,
  },
  methods: {
    ...mapActions(["setTemplateType"]),
    pulsarPlantilla(templateName) {
      this.setTemplateType(templateName);
      this.$router.push({ path: "/hacercurriculum" });
    },
  },
  mounted() {},
};
</script>

<style scoped>
.paddingH3em {
  padding-top: 3em;
  padding-bottom: 3em;
  background-color: #fefefe;
}
.paddingH3em .col-3 {
  align-self: baseline;
}
#homeContainer {
  background: #f3f2ef;
  height: 100%;
}
#currentTemplate {
  z-index: 100;
  position: absolute;
  background: transparent;
  width: 100%;
  top: 200px;
}
.onLeft,
.onRight {
  position: absolute;
}
.carousel-3d-container {
  height: 500px !important;
  margin-bottom: 4em;
}
.carousel-3d-slide {
  border: none;
  margin-top: 1em;
  box-shadow: 0px 6px 6px -3px rgb(0 0 0 / 20%),
    0px 10px 14px 1px rgb(0 0 0 / 14%), 0px 4px 18px 3px rgb(0 0 0 / 12%);
}
.carousel-3d-slide h3 {
  background: #f3f2ef;
  text-align: center;
}
.v-avatar {
  float: right;
}
</style>
