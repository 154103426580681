<template>
  <v-footer>
    <v-row justify="center" no-gutters>
      <v-btn
        v-for="link in links"
        :key="link.name"
        :href="link.url"
        target="_blank"
        color="#C4C4C6"
        text
        rounded
        class="my-2 caption"
      >
        {{ link.name }}
      </v-btn>
    </v-row>
  </v-footer>
</template>

<script>
export default {
  data: function () {
    return {
      links: [
        {
          name: this.$i18n.t("conditions"),
          url: this.$i18n.t("conditionsUrl"),
        },
        {
          name:  this.$i18n.t("legal"),
          url:  this.$i18n.t("legalUrl"),
        },
        {
          name:  this.$i18n.t("cookies"),
          url:  this.$i18n.t("cookiesUrl"),
        },
        {
          name: this.$i18n.t("privacy"),
          url: this.$i18n.t("privacyUrl"),
        },
      ],
    };
  },
};
</script>

<style scoped>
.v-footer {
  background-color: #fefefe !important;
  border-color: #fefefe !important;
  padding-top: 10px;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
}

@media only screen and (max-width: 600px) {
  .v-footer {
    position: relative;
  }
}
</style>
